<template>
  <base-modal-form
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
    :id="idModal"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <input
        type="hidden"
        name="process_value"
        v-model="formFields.process_value"
      />
      <div class="flex space-x-4">
        <b-form-group
          label="Juzgado"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.court_id)"
        >
          <input
            type="hidden"
            name="court_id"
            v-model="$v.formFields.court_id.$model"
          />
          <v-select
            :options="courts"
            :reduce="court => court.value"
            label="text"
            v-model.trim="$v.formFields.court_id.$model"
            :state="validateState('court_id')"
            :disabled="isDisabled"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Juez"
          class="flex-1 required"
          :state="validateState('judge_id')"
        >
          <input
            type="hidden"
            name="judge_id"
            v-model="$v.formFields.judge_id.$model"
          />
          <v-select
            :options="judges"
            :reduce="judge => judge.value"
            label="text"
            v-model.trim="$v.formFields.judge_id.$model"
            :state="validateState('warranty_garment')"
            :disabled="isDisabled"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Abogado"
          class="flex-1 required"
          :state="validateState('lawyer_id')"
        >
          <input
            type="hidden"
            name="lawyer_id"
            v-model="$v.formFields.lawyer_id.$model"
          />
          <v-select
            :options="lawyers"
            :reduce="lawyer => lawyer.value"
            label="text"
            v-model.trim="$v.formFields.lawyer_id.$model"
            :disabled="isDisabled"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Cliente"
          class="flex-1 required"
          :state="validateState('client_id')"
        >
          <input
            type="hidden"
            name="client_id"
            v-model="$v.formFields.client_id.$model"
          />
          <v-select
            :options="clients"
            :reduce="client => client.value"
            label="text"
            v-model.trim="$v.formFields.client_id.$model"
            :disabled="disabled || isDisabled"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Tipo proceso"
          class="flex-1 required"
          :state="validateState('process_type_id')"
        >
          <input
            type="hidden"
            name="process_type_id"
            v-model="$v.formFields.process_type_id.$model"
          />
          <v-select
            :options="processTypes"
            :reduce="processType => processType.value"
            label="text"
            v-model.trim="$v.formFields.process_type_id.$model"
            :disabled="isDisabled"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Ultimo estado"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.last_state_id)"
        >
          <input
            type="hidden"
            name="last_state_id"
            v-model="$v.formFields.last_state_id.$model"
          />
          <v-select
            :options="stateProcess"
            :reduce="stateProces => stateProces.value"
            label="text"
            v-model.trim="$v.formFields.last_state_id.$model"
            :state="validateState('last_state_id')"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            Campo requerido
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group label="Radicado" class="flex-1 ">
          <b-form-input name="settled" v-model.trim="formFields.settled">
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Valor del proceso"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.process_value)"
        >
          <b-form-input
            v-currency="optionsCurrency"
            v-model.trim="$v.formFields.process_value.$model"
            :state="validateState('process_value')"
            :disabled="isDisabled"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Fecha de inicio"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.start_date)"
        >
          <b-form-input
            type="date"
            name="start_date"
            v-model.trim="$v.formFields.start_date.$model"
            :state="validateState('start_date')"
            :disabled="isDisabled"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Fecha de admisión" class="flex-1">
          <b-form-input
            type="date"
            name="admission_date"
            v-model.trim="formFields.admission_date"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Prenda de garantía"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.warranty_garment)"
        >
          <b-form-input
            name="warranty_garment"
            v-model.trim="$v.formFields.warranty_garment.$model"
            :state="validateState('warranty_garment')"
            :disabled="isDisabled"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Corte de título de entrega"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.delivery_title_court)"
        >
          <b-form-input
            name="delivery_title_court"
            v-model.trim="$v.formFields.delivery_title_court.$model"
            :state="validateState('delivery_title_court')"
            :disabled="isDisabled"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Observaciones"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.notes_observations)"
        >
          <b-form-textarea
            name="notes_observations"
            v-model.trim="$v.formFields.notes_observations.$model"
            :state="validateState('notes_observations')"
            rows="3"
            max-rows="6"
            :disabled="isDisabled"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";
import { CurrencyDirective } from "vue-currency-input";
export default {
  props: [
    "handlePostSubmit",
    "record",
    "id",
    "idModal",
    "disabled",
    "isDisabled"
  ],
  mixins: [customComponentActions],
  inject: [
    "processLawyersRepository",
    "clientsRepository",
    "courtsRepository",
    "judgesRepository",
    "lawyersRepository",
    "processTypeRepository",
    "stateProcessRepository"
  ],

  components: {
    BaseModalForm
  },

  directives: {
    currency: CurrencyDirective
  },

  data() {
    return {
      formFields: {
        id: null,
        warranty_garment: "",
        settled: "",
        process_value: 0,
        start_date: "",
        last_state_id: null,
        notes_observations: "",
        delivery_title_court: "",
        court_id: null,
        judge_id: null,
        lawyer_id: null,
        client_id: null,
        process_type_id: null,
        is_personal: false,
        admission_date: ""
      },

      repository: "processLawyersRepository",
      primary: "id",
      clients: [],
      lawyers: [],
      courts: [],
      judges: [],
      processTypes: [],
      stateProcess: [],
      optionsCurrency: {
        locale: "es",
        currency: {
          prefix: "$"
        }
      },
      statuManaged: false
    };
  },

  validations: {
    formFields: {
      warranty_garment: {
        required
      },
      process_value: {
        required
      },
      start_date: {
        required
      },
      last_state_id: {
        required
      },
      notes_observations: {
        required
      },
      delivery_title_court: {
        required
      },
      court_id: {
        required
      },
      judge_id: {
        required
      },
      lawyer_id: {
        required
      },
      client_id: {
        required
      },
      process_type_id: {
        required
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = {
          ...me.formFields,
          ...me.record,
          ...{ client_id: me.record.id }
        };

        if (me.record.client_id) me.formFields.client_id = me.record.client_id;
      }
    }
  },

  async mounted() {
    const me = this;

    let clients = await me.clientsRepository.get();
    let lawyers = await me.lawyersRepository.get();
    let courts = await me.courtsRepository.get();
    let judges = await me.judgesRepository.get();
    let processTypes = await me.processTypeRepository.get();
    let stateProcess = await me.stateProcessRepository.get();

    me.clients = [
      {
        value: null,
        text: "Seleccione cliente"
      },
      ...deserialize(clients.data).map(client => {
        return {
          value: client.id,
          text: client.full_name
        };
      })
    ];

    me.courts = [
      {
        value: null,
        text: "Seleccione juzgado"
      },
      ...deserialize(courts.data).map(court => {
        return {
          value: court.id,
          text: court.name
        };
      })
    ];

    me.lawyers = [
      {
        value: null,
        text: "Seleccione abogado"
      },
      ...deserialize(lawyers.data).map(lawyer => {
        return {
          value: lawyer.id,
          text: lawyer.full_name
        };
      })
    ];

    me.judges = [
      {
        value: null,
        text: "Seleccione juez"
      },
      ...deserialize(judges.data).map(judge => {
        return {
          value: judge.id,
          text: judge.name
        };
      })
    ];

    me.processTypes = [
      {
        value: null,
        text: "Seleccione tipo proceso"
      },
      ...deserialize(processTypes.data).map(processType => {
        return {
          value: processType.id,
          text: processType.name
        };
      })
    ];

    me.stateProcess = [
      {
        value: null,
        text: "Seleccione estado"
      },
      ...deserialize(stateProcess.data).map(stateProces => {
        return {
          value: stateProces.id,
          text: stateProces.concept
        };
      })
    ];
  }
};
</script>

<style lang="scss" scoped></style>
